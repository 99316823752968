export const NFItemColumns = [
  {
    field: 'num_item',
    headerName: 'Item',
    width: 40,
  },
  {
    field: 'cod_gtin',
    headerName: 'GTIN',
    width: 130,
  },
  {
    field: 'des_produto',
    headerName: 'Descrição',
    width: 250,
  },
  {
    field: 'des_unidade',
    headerName: 'UN Cpa.',
    width: 40,
  },
];
