import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ColumnSeparator, Container, TableEmpty } from './styles';
import { CustomGridColumnsProps } from '../../protocols';

// import { GridColumns } from '@material-ui/data-grid';
interface FormProps {
  rows: any[];
  columns: CustomGridColumnsProps[];
  Select?: number;
  emptyLineMessage?: string;
  isEmptyLineMessage?: boolean;
  isPaginated?: boolean;
}

/**
 * Linhas e colunas para exemplo
 */

/**
 * @param rows Array de objetos contendo as linhas da tabela (as keys dos objetos devem ser iguais as ids das colunas)
 * @param columns Array de objetos contendo as colunas
 * @param columns Para fixar uma coluna basta passar uma chave chamada fixed com o valor true e Left com 0 (também é necessário passar um width e um minWidth)
 * @param columns Caso mais de uma coluna seja fixa, a coluna seguinte deverá receber o valor de Left igual ao minWidth da coluna anterior
 * @returns Componente DataGrid personalizado
 */

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const {
    rows,
    columns,
    Select,
    emptyLineMessage = '',
    isEmptyLineMessage = false,
    isPaginated = true,
  } = props;
  // const rows = ExRows;
  // const columns = ExColumns;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const tableRef = useRef<any>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef.current.scrollTop = 0;
  };
  function Format({
    value,
    format,
  }: {
    value: any;
    format: string | undefined;
  }) {
    // eslint-disable-next-line no-bitwise
    if (value === null) {
      return '';
    }
    if (value === undefined) {
      return '';
    }
    if (format === 'data') {
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
    if (format === 'real') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    }
    return value;
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowsConditional = isPaginated
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows;

  return (
    <Container>
      <Paper
        style={{
          width: '100%',
          height: '100%',
          maxHeight: '320px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <TableContainer
          style={{
            flex: 1,
            zIndex: 0,
          }}
          ref={tableRef}

          // className="d-flex justify-content-between"
        >
          {/* <TableContainer ref={tableRef}> */}
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {/* <TableRow> */}
              {/* <TableCell align="center" colSpan={3} /> */}
              {/* <TableCell align="center" colSpan={2}>
                  Tipos
                </TableCell> */}
              {/* </TableRow> */}
              <TableRow>
                {columns.map((column) => {
                  if (column.fixed)
                    return (
                      <TableCell
                        key={column.field}
                        className="text-truncate"
                        style={{
                          position: 'sticky',
                          right: 0,
                          zIndex: 2,
                          width: column.width,
                          minWidth: column.width,
                          background: '#57069e',
                          textAlign: 'center',
                        }}
                      >
                        {column.headerName}
                        {/* <ColumnSeparator>
                          <svg
                            className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M11 19V5h2v14z" />
                          </svg>
                        </ColumnSeparator> */}
                      </TableCell>
                    );
                  return (
                    <TableCell
                      key={column.field}
                      className="text-truncate"
                      style={{
                        minWidth: column.minWidth
                          ? column.minWidth
                          : column.width,
                        position: 'sticky',
                        zIndex: 2,
                        paddingBottom: 2,
                        paddingTop: 2,
                      }}
                    >
                      {column.headerName}
                      <ColumnSeparator>
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          style={{ marginTop: '-25px' }}
                          // focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>
                      </ColumnSeparator>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsConditional.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    // tabIndex={-1}
                    className={Select === row.cod_xml ? 'selected' : 'rowTable'}
                    // eslint-disable-next-line react/no-array-index-key
                    key={rowIndex}
                  >
                    {columns.map((column) => {
                      if (column.fixed)
                        return (
                          <TableCell
                            key={column.field}
                            style={{
                              position: 'sticky',
                              right: 0,
                              background:
                                Select === row.cod_xml
                                  ? 'rgb(220, 252, 251)'
                                  : '#fff',
                              width: column.width,
                              minWidth: column.width,
                            }}
                          >
                            {column.renderCell
                              ? column.renderCell(row)
                              : Format({
                                  value: row[column.field],
                                  format: column.format,
                                })}
                          </TableCell>
                        );
                      return (
                        <TableCell
                          key={column.field}
                          style={{
                            background:
                              Select === row.cod_xml
                                ? 'rgb(220, 252, 251)'
                                : '#fff',
                          }}
                        >
                          {column.renderCell
                            ? column.renderCell(row)
                            : Format({
                                value: row[column.field],
                                format: column.format,
                              })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {isEmptyLineMessage && rows.length === 0 && (
            <TableEmpty>{emptyLineMessage}</TableEmpty>
          )}
        </TableContainer>
        {isPaginated && (
          <TablePagination
            // style={
            //   {
            //     position: 'absolute',
            //     right: 0,
            //     bottom: 0,
            //     width: '100%',
            //     overflow: 'auto',
            //   }
            // }
            // rowsPerPageOptions={[5, 10, 25, 100]}
            rowsPerPageOptions={[-1]}
            onPageChange={handleChangePage}
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        )}
        {/* <div
          className="d-flex"
          style={{
            position: 'relative',
            alignSelf: 'end',
            bottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div />
          <TablePagination
            // style={
            //   {
            //     position: 'absolute',
            //     right: 0,
            //     bottom: 0,
            //     width: '100%',
            //     overflow: 'auto',
            //   }
            // }
            rowsPerPageOptions={[5, 10, 25, 100]}
            onChangePage={handleChangePage}
            onPageChange={handleChangePage}
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </div> */}
      </Paper>
    </Container>
  );
};

export default CustomDataGrid;
