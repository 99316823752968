import React, { useCallback, useState } from 'react';

import { useBuscaNfe } from '../../BuscaNfeContext';

import { Row } from 'react-bootstrap';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
} from '@material-ui/core';
import { nanoid } from 'nanoid';
import { moneyFormat } from '~/utils/functions';
import moment from 'moment';
import api from '~/services/api';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export type NfSelecionada = {
  cod_xml: number;
  num_nf: number;
};

export const TableNF: React.FC = () => {
  const {
    nfs,
    setNf,
    loading,
    setCodXml,
    getNFItens,
    setFornecedor,
    nfSelecionada,
    setNFSelecionada,
  } = useBuscaNfe();

  const buscaParceiro = useCallback(
    async (cod_pessoa: string) => {
      try {
        const promise = api.get(`/nf/busca-fornecedor/${cod_pessoa}`);
        const [resp] = await Promise.all([promise]);
        const { data } = resp;

        if (data.success && data.data.length > 0) {
          setFornecedor(data.data[0]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [setFornecedor],
  );

  const handleLoadItems = useCallback(
    async (nf) => {
      const { cod_xml, num_nf } = nf;
      setNFSelecionada({
        cod_xml,
        num_nf,
      });
      setNf(nf);
      setCodXml(cod_xml);
      buscaParceiro(nf.cod_pessoa);
      await getNFItens(cod_xml, nf);
    },
    [buscaParceiro, getNFItens, setCodXml, setNFSelecionada, setNf],
  );

  return (
    <Row>
      <TableContainer style={{ maxHeight: '250px', zIndex: 0 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                Loja
              </TableCell>
              <TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                Fornecedor
              </TableCell>
              <TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                NF
              </TableCell>
              <TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                Qtd Itens
              </TableCell>
              <TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                Valor
              </TableCell>
              <TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                Emissão
              </TableCell>
              <TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                Processamento
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nfs.length > 0 &&
              nfs.map((nf) => {
                return (
                  <TableRow
                    onClick={async () => {
                      await handleLoadItems(nf);
                    }}
                    key={nanoid()}
                    title={
                      nfSelecionada?.cod_xml === nf.cod_xml
                        ? 'NF Selecionada'
                        : Number(nf.tipo_status) === 2
                        ? 'NF Validada'
                        : 'NF Disponível para validação'
                    }
                    // style={{
                    //   background:
                    //     nfSelecionada?.cod_xml === nf.cod_xml
                    //       ? '#dcfcfb'
                    //       : Number(nf.tipo_status) === 2
                    //         ? '#81FF81'
                    //         : '#ffff81',
                    // }}
                    style={{
                      background:
                        nfSelecionada?.cod_xml === nf.cod_xml ? '#dcfcfb' : '',
                    }}
                  >
                    <TableCell style={{ color: '#333333' }}>
                      {nf.cod_loja}
                    </TableCell>
                    <TableCell style={{ color: '#333333' }}>
                      {nf.des_fornecedor}
                    </TableCell>
                    <TableCell style={{ color: '#333333' }}>
                      {nf.num_nf}
                    </TableCell>
                    <TableCell style={{ color: '#333333' }}>
                      {nf.qtd_itens}
                    </TableCell>
                    <TableCell style={{ color: '#333333' }}>
                      {moneyFormat(nf.val_total_nf, 2)}
                    </TableCell>
                    <TableCell style={{ color: '#333333' }}>
                      {nf.dta_emissao && nf.dta_emissao.trim() !== ''
                        ? moment(
                            createDateWithoutTimezone(nf.dta_emissao),
                          ).format('DD/MM/YYYY')
                        : ''}
                    </TableCell>
                    <TableCell style={{ color: '#333333' }}>
                      {nf.dta_processamento &&
                      nf.dta_processamento.trim() !== ''
                        ? moment(
                            createDateWithoutTimezone(nf.dta_processamento),
                          ).format('DD/MM/YYYY')
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {loading === 'items' && (
          <LinearProgress
            style={{
              background: '#c2a0f8',
              color: '#8850BF',
            }}
          />
        )}
      </TableContainer>
    </Row>
  );
};
