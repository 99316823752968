/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import ButtonAtlaskit from '@atlaskit/button';

import Loja from '~/components/Loja';
import {
  BuscaParceiro,
  InputDate,
  InputNumber,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';

import { useBuscaNfe } from '../BuscaNfeContext';
import { BtnGroupContainer, ContainerButtons, GroupItem } from '../styles';
import { MdCheck, MdOutlineCancel } from 'react-icons/md';
import { TableNF } from './TableNF';
import { TableNFItem } from './TableNFItem';
import { CircularProgress } from '@material-ui/core';
import { IoIosSearch } from 'react-icons/io';
import { FaEraser } from 'react-icons/fa';
import { BootstrapModal } from '../MyComponents/CustomDataGrid/styles';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

export const ModalBuscaNfe: React.FC = () => {
  const {
    isOpenModal,
    setIsOpenModal,
    loja,
    setLoja,
    setFornecedor,
    tipo_periodo,
    setTipo_periodo,
    loading,
    register,
    setFocus,
    errors,
    control,
    setValue,
    handleSearch,
    handleCancel,
    disable,
    ClearInputs,
    getNF,
    getValues,
    clearErrors,
  } = useBuscaNfe();

  const handleCloseModal = () => {
    setIsOpenModal(false);
    ClearInputs();
  };

  useEffect(() => {
    const field = getValues('num_nf');
    if (field) {
      setFocus('num_nf');
    }
  }, [getValues, setFocus, isOpenModal]);

  return (
    <BootstrapModal
      show={isOpenModal}
      onHide={handleCloseModal}
      centered
      // size="xl"
      dialogClassName="custom-modal"
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title>
          XML de Notas Fiscais Validadas
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <Row style={{ alignItems: 'flex-end' }}>
          <Col md={5} sm={12}>
            <div
              style={{
                borderBottom: errors.cod_loja ? '2px solid #cf1919' : '',
              }}
            >
              <Loja
                selectedLoja={loja}
                IsInvalid={false}
                onChange={(e: number) => {
                  setValue('cod_loja', e);
                  setLoja(e);
                }}
                isDisabled={disable}
              />
            </div>
          </Col>
          <Col md={7} sm={12}>
            <BuscaParceiro
              label="Fornecedor"
              placeholder="Selecione..."
              name="busca_parceiro"
              register={register}
              isError={!!errors.busca_parceiro}
              control={control}
              customOptions={{
                buscarPor: { fornecedor: true },
              }}
              changeSelected={(selected: any) => {
                setValue('busca_parceiro', selected);
                setFornecedor(selected);
                clearErrors('busca_parceiro');
              }}
              disabled={disable}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={12}>
            <InputNumber
              label="Nº NF"
              maxLength={9999999999}
              placeholder=""
              name="num_nf"
              min={0}
              max={9999999999}
              register={register}
              disabled={disable}
              isError={!!errors.num_nf}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch(event);
                }
              }}
            />
          </Col>
          <Col md={6} sm={12}>
            <GroupItem style={{ height: '60px', marginTop: '20px' }}>
              <span className="label"> Período:</span>
              <BtnGroupContainer>
                <ButtonAtlaskit
                  value="0"
                  className={
                    tipo_periodo === 0
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  type="button"
                  isDisabled={disable}
                  onClick={() => {
                    setTipo_periodo(0);
                    setValue('tipo_periodo', 0);
                  }}
                >
                  Emissão
                </ButtonAtlaskit>
                <ButtonAtlaskit
                  value="1"
                  className={
                    tipo_periodo === 1
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  type="button"
                  isDisabled={disable}
                  onClick={() => {
                    setTipo_periodo(1);
                    setValue('tipo_periodo', 1);
                  }}
                >
                  Processada
                </ButtonAtlaskit>
              </BtnGroupContainer>
            </GroupItem>
          </Col>
          <Col md={2} sm={12}>
            <InputDate
              label="De"
              placeholder=""
              name="dta_de"
              register={register}
              control={control}
              disabled={disable}
              isError={!!errors.dta_de}
              onChange={(event: any) => {
                setValue('dta_de', event.target?.value);
              }}
            />
          </Col>
          <Col md={2} sm={12}>
            <InputDate
              label="Até"
              placeholder=""
              name="dta_ate"
              register={register}
              control={control}
              disabled={disable}
              isError={!!errors.dta_ate}
              onChange={(event: any) => {
                setValue('dta_ate', event.target?.value);
              }}
            />
          </Col>
        </Row>

        <ContainerButtons>
          <CustomButtonNovo
            onClick={handleSearch}
            label="Pesquisar"
            icon={IoIosSearch}
            disabled={disable}
            showLoading={loading === 'pesquisar'}
          />
          <CustomButtonNovo
            disabled={!disable}
            onClick={handleCancel}
            label="Cancelar"
            icon={MdOutlineCancel}
            variant="cancel"
          />
          <CustomButtonNovo
            onClick={ClearInputs}
            disabled={disable}
            label="Limpar"
            icon={FaEraser}
            variant="clear"
          />
        </ContainerButtons>

        {/* INICIO TABLES */}
        <TableNF />
        <Separator labelText="Itens" marginTop="0" />
        <TableNFItem />
        {/* FIM TABLES */}
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <ContainerButtons>
          <Button
            className="button-action success"
            variant="success"
            style={{ border: 'none' }}
            onClick={getNF}
          >
            {loading === 'selecionar' ? (
              <>
                <CircularProgress size={15} style={{ color: '#ffffff' }} />
              </>
            ) : (
              <>
                <MdCheck size={25} />
                Selecionar
              </>
            )}
          </Button>
          <Button
            className="button-action fechar"
            variant="primary"
            style={{ border: 'none' }}
            onClick={handleCloseModal}
          >
            Fechar
          </Button>
        </ContainerButtons>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};
